// Timer 2.0
import { getTodaysWorkout } from './workoutGenerator.js';
import { setExerciseText, speakText, updateTimerDisplayCoral, updateTimerDisplayYellow, playBeepSound, updateUpNextText } from './ui.js';
import { getPhases } from './workoutTimes.js';
import { onWorkoutComplete } from './endWorkout.js';

let todaysWorkout = getTodaysWorkout();

const COUNTDOWN_INITIAL_TIME = 10;
let EXERCISE_TIME = 5;
let BREAK_TIME = 5;

const BEEP_TIMES = [3, 2, 1];

const PHASES = {
    getReady: { time: COUNTDOWN_INITIAL_TIME },
    exercise: { time: EXERCISE_TIME },
    break: { time: BREAK_TIME }
};

const workoutState = {
    currentExercise: 0,
    currentPhase: PHASES.getReady,
    animationFrameId: null,
    isPaused: false,
    remainingPhaseTime: 0,
    endTime: 0,
    beepIndex: 0
};

// Main function to start the timer
export function startTimer() {
    console.log(`Exercise Time: ${EXERCISE_TIME}, Break Time: ${BREAK_TIME}`);
    workoutState.remainingPhaseTime = workoutState.currentPhase.time;
    workoutState.endTime = Date.now() + workoutState.remainingPhaseTime * 1000;
    updatePhaseTimes();
    // Fetch the workout again in case it has doubled.
    todaysWorkout = getTodaysWorkout();
    enablePauseResumeListeners();

    const nextPhaseText = getNextPhaseText();
    updateUpNextText(nextPhaseText);

    updateTimer();
    console.log("So far everything worked!");
}

// Main animation loop for updating the timer and handling phase transitions
function animate() {
    if (workoutState.isPaused) return;

    const now = Date.now();
    workoutState.remainingPhaseTime = Math.max(0, workoutState.endTime - now);
    const remainingSeconds = Math.ceil(workoutState.remainingPhaseTime / 1000);
    const percentage = ((workoutState.currentPhase.time * 1000 - workoutState.remainingPhaseTime) / (workoutState.currentPhase.time * 1000)) * 100;

    // Update the display based on the current phase
    if (workoutState.currentPhase === PHASES.getReady || workoutState.currentPhase === PHASES.break) {
        updateTimerDisplayCoral(remainingSeconds, percentage);
    } else if (workoutState.currentPhase === PHASES.exercise) {
        updateTimerDisplayYellow(remainingSeconds, percentage);
    }

    // Play beep sound at specific times
    if (workoutState.beepIndex < BEEP_TIMES.length && remainingSeconds === BEEP_TIMES[workoutState.beepIndex]) {
        playBeepSound();
        workoutState.beepIndex++;
    }

    // Check if phase time is up and transition or continue animation
    if (workoutState.remainingPhaseTime <= 0) {
        cancelAnimationFrame(workoutState.animationFrameId);
        transitionToNextPhase();
    } else {
        workoutState.animationFrameId = requestAnimationFrame(animate);
    }
}

// Starts or resumes the timer animation
function updateTimer() {
    if (!workoutState.isPaused) {
        workoutState.endTime = Date.now() + workoutState.remainingPhaseTime * 1000;
    }

    workoutState.animationFrameId = requestAnimationFrame(animate);
    console.log("updateTimer is called");
}

// Handles phase transitions and starts the appropriate phase
function transitionToNextPhase() {
    if (workoutState.currentPhase === PHASES.getReady) {
        startExercise();
    } else if (workoutState.currentPhase === PHASES.break) {
        finishBreak();
    } else if (workoutState.currentPhase === PHASES.exercise) {
        finishExercise();
    }
}

// Starts the exercise phase
function startExercise() {
    const exerciseName = todaysWorkout[workoutState.currentExercise].name;
    setExerciseText(exerciseName);
    speakText(exerciseName);
    workoutState.currentPhase = PHASES.exercise;
    workoutState.remainingPhaseTime = PHASES.exercise.time;
    workoutState.beepIndex = 0;

    const nextPhaseText = getNextPhaseText();
    updateUpNextText(nextPhaseText);

    updateTimer();
}

// Ends the exercise phase and starts a break or finishes the workout
function finishExercise() {
    if (workoutState.currentExercise < todaysWorkout.length - 1) {
        startBreak();
    } else {
        finishWorkout();
    }
}

// Starts the break phase
function startBreak() {
    setExerciseText("Break");
    speakText("Break");
    workoutState.currentPhase = PHASES.break;
    workoutState.remainingPhaseTime = PHASES.break.time;
    workoutState.beepIndex = 0;

    const nextPhaseText = getNextPhaseText();
    updateUpNextText(nextPhaseText);

    updateTimer();
}

// Ends the break phase and starts the next exercise
function finishBreak() {
    workoutState.currentExercise++;
    startExercise();
}

// Completes the workout and disables interactions
function finishWorkout() {
    setExerciseText("Workout Complete!");
    document.getElementById("countdown").style.display = "none";
    document.getElementById('up-next-text').style.display = "none";
    disablePauseResumeListeners();
    onWorkoutComplete();
}

// Returns the text for the next phase
function getNextPhaseText() {
    if (workoutState.currentPhase === PHASES.getReady) {
        return `Up Next: ${todaysWorkout[workoutState.currentExercise].name}`;
    } else if (workoutState.currentPhase === PHASES.exercise) {
        if (workoutState.currentExercise < todaysWorkout.length - 1) {
            return "Up Next: Break";
        } else {
            return "Finish Strong!";
        }
    } else if (workoutState.currentPhase === PHASES.break) {
        if (workoutState.currentExercise < todaysWorkout.length - 1) {
            return `Up Next: ${todaysWorkout[workoutState.currentExercise + 1].name}`;
        } else {
            return null;
        }
    }

    return "";
}

// Updates the phase times dynamically
function updatePhaseTimes() {
    let updatedPhaseObject = getPhases();
    PHASES.exercise.time = updatedPhaseObject.exercise.time;
    PHASES.break.time = updatedPhaseObject.break.time;
    console.log("Phase times should be updated now.");
}

// Enables pause/resume event listeners
function enablePauseResumeListeners() {
    document.getElementById("circle-container").addEventListener("click", togglePauseResume);
    document.addEventListener("keydown", pauseResumeOnSpace);
    console.log("Pause resume listeners should now be enabled");
}

// Disables pause/resume event listeners
function disablePauseResumeListeners() {
    document.getElementById("circle-container").removeEventListener("click", togglePauseResume);
    document.removeEventListener("keydown", pauseResumeOnSpace);
}

// Toggles pause/resume functionality for the timer
function togglePauseResume() {
    if (workoutState.isPaused) {
        workoutState.isPaused = false;
        workoutState.endTime = Date.now() + workoutState.remainingPhaseTime * 1000;
        updateTimer();
    } else {
        workoutState.remainingPhaseTime = (workoutState.endTime - Date.now()) / 1000;
        cancelAnimationFrame(workoutState.animationFrameId);
        workoutState.isPaused = true;
    }
}

// Handles space bar press to toggle pause/resume
function pauseResumeOnSpace(event) {
    if (event.code === "Space") {
        event.preventDefault();
        togglePauseResume();
    }
    console.log("pause resume on space ran");
}